<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="pcard">
            <div class="img-container">
                <img :src="img" :alt="name" />
            </div>
            <div class="pcard-detail">
                <h1>{{ name }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'PCard',
    components: {
        Loader,
    },
    mixins: [loaderHandler],
    props: ['name', 'img', 'id'],
    mounted() {
        setTimeout(() => {
            this.loaderShow = false
        }, 100)
    },
}
</script>

<style lang="scss">
@import './PCard.scss';
</style>
