<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="designerdetail-portfolio">
            <div class="header">
                <h1>Portfolio</h1>
            </div>
            <div class="portfolios-container">
                <div
                    v-for="portfolio in portfolio"
                    :key="`portfolio_` + portfolio.id"
                    class="portfolio-col"
                >
                    <!-- <router-link :to="{name: 'DesignerDetailGroupDesktop', params: { group_id: portfolio.id },}"> -->
                    <PCard
                        :name="portfolio.group_name"
                        :img="portfolio.group_image_thumb"
                        :id="portfolio.id"
                        @click.native="goToGroup(portfolio.id)"
                    />
                    <!-- </router-link> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PCard from 'components/PCard'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'DesignerDetailPortfolio',
    components: {
        PCard,
        Loader,
    },
    mixins: [loaderHandler],

    props: ['portfolio'],
    mounted() {
        setTimeout(() => {
            this.loaderShow = false
        }, 100)
    },
    methods: {
        async goToGroup(id) {
            this.$emit('changeComponent', {
                componentName: 'DesignerDetailGroup',
                id: id,
            })
        },
    },
}
</script>

<style lang="scss">
@import './DesignerDetailPortfolio.scss';
</style>
